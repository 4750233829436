@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  overflow-x: hidden;
}
.container {
  padding: 0 10px;
}
.ant-menu-sub {
  background-color: transparent !important;
}

.product-list .ant-menu-title-content {
  transition: color 0.3s;
  font-size: 13px;
  display: inline-block;
}
.sidebar .ant-dropdown-trigger .ant-menu-title-content {
  padding-left: 24px;
}
.ant-menu-title-content {
  transition: color 0.3s;
  font-size: 15px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.anticon.anticon-down {
  margin-left: 5px !important;
  margin-top: 2px;
  font-size: 10px !important;
}
.ant-dropdown-menu-title-content {
  font-size: 15px;
  transition: color 0.3s;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.hero-carousel .slick-prev {
  position: absolute;
  top: 50%;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.hero-carousel .slick-prev::after {
  top: 10px !important;
  left: 20px !important;
  transform: rotate(-45deg) translate(-50%, -50%) !important;
}

.hero-carousel .slick-next {
  position: absolute;
  top: 50%;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.hero-carousel .slick-next::after {
  top: 10px !important;
  left: 0px !important;
  transform: rotate(135deg) translate(-50%, -50%) !important;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  background: rgb(191 219 254) !important;
  margin-inline: 4px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.3s;
}
.slick-dots li.slick-active {
  width: 24px;
}
.slick-dots li.slick-active button {
  width: 24px;
  background-color: rgb(29 78 216) !important;
}
.slick-dots li button:before {
  content: none !important;
}

.featured-product .slick-prev {
  position: absolute;
  top: 50%;
  left: -30px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.featured-product .slick-prev::after {
  top: 10px !important;
  left: 20px !important;
  transform: rotate(-45deg) translate(-50%, -50%) !important;
}

.featured-product .slick-next {
  position: absolute;
  top: 50%;
  right: -30px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.featured-product .slick-next::after {
  top: 10px !important;
  left: 0px !important;
  transform: rotate(135deg) translate(-50%, -50%) !important;
}

.testimonial .slick-prev {
  position: absolute;
  top: 50%;
  left: -20px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.testimonial .slick-prev::after {
  top: 10px !important;
  left: 20px !important;
  transform: rotate(-45deg) translate(-50%, -50%) !important;
}

.testimonial .slick-next {
  position: absolute;
  top: 50%;
  right: -20px;
  width: 32px !important;
  height: 32px !important;
  border-radius: 100% !important;
  background-color: white !important;
  border: 1px solid gray !important;
  transform: translateY(-50%);
  color: #000 !important;
  opacity: 0.4;
  background: transparent;
  padding: 0;
  line-height: 0;
  border: 0;
  outline: none;
  cursor: pointer;
  z-index: 1;
  transition: opacity 0.3s;
}
.testimonial .slick-next::after {
  top: 10px !important;
  left: 0px !important;
  transform: rotate(135deg) translate(-50%, -50%) !important;
}

.component {
  font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  /* width: 750px;
  margin: 0 auto;
  padding: 3rem; */
}
.component blockquote.quote {
  position: relative;
  text-align: center;
  padding: 1rem 1.2rem;
  color: #484748;
  margin: 1rem auto 2rem;
}
.component blockquote.EN {
  background: linear-gradient(to right, #4f46e5 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #4f46e5 4px, transparent 4px) 100% 0,
    linear-gradient(to bottom, #4f46e5 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #4f46e5 4px, transparent 4px) 0 100%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}
